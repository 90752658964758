import React from 'react'
import UnderConstruction from '../UnderConstruction'

function ServicesTwo() {
  return (
    <div>
        <UnderConstruction/>
    </div>
  )
}

export default ServicesTwo