import React from 'react';
import UnderConstruction from './UnderConstruction';

const Contact = () => {
  return (
    <section>
     <UnderConstruction/>
    </section>
  );
};

export default Contact;
