import React from 'react';

const UpIcon = ({ height , width  }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 15L12 9L6 15"
      stroke="#000"
      strokeWidth="2"
    />
  </svg>
);

export default UpIcon;
