import React from "react";
import CustomCarousel from "../../components/carousel/CoustomCarousel";

const Home = () => {
  return (
    <div>
      <CustomCarousel />
    </div>
  );
};

export default Home;
